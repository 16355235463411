import React, { useState } from "react";
import HeroV3 from "../components/Intake/HeroV3";
import AffiliateWhyHubble from "../components/Intake/AffiliateWhyHubble"
import AffiliatePress from "../components/Intake/AffiliatePress"
import "../styles/pages/intake-hydro-skyhy.scss"
import AffiliateCallToAction from '../components/Intake/AffiliateCallToAction';
import HomeCtaAvg from "../components/HomeCtaAvg";
import Nav from "../components/CanadaSignup/Nav";
import ContactsCharacteristics from "../components/CanadaSignup/ContactsCharacteristics";
import Footer from "../components/CanadaSignup/Footer";
import OrderSection from "../components/CanadaSignup/OrderSection";

const IntakePage = () => {
  return (
    <div className="hubble-content-hydro-skyhy v3">
      <Nav usLink="/" />
      <HeroV3 isHydroSkyhy/>
      <AffiliateCallToAction isIntake />
      <ContactsCharacteristics prefill />
      <AffiliateWhyHubble isV3/>
      <HomeCtaAvg />
      <AffiliatePress />
      <OrderSection />
      <Footer/>
    </div>
  );
}

export default IntakePage
