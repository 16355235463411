import React, {useContext, useEffect, useState} from 'react';
import WebpImage from '../../WebpImage';
import { usePromo, promoStart, promoEnd } from "../../../utils/usePromo";
import './styles.scss';
import ProductTag from '../../ProductTag';
import {lensesData} from "./lensesData";
import HubbleOrderContext from "../../../context/hubbleOrderContext";
import scrollTo from "gatsby-plugin-smoothscroll";
import {useWindowWidth} from "../../../services/hooks";
import {getHubblePricing} from "../../../services/data";

const ContactsCharacteristics = ({
  prefill
}) => {
  const isPromo = usePromo(promoStart, promoEnd)
  const { setBrand: setContextBrand, setBrandSelected, setTaxes, setShippingAddress} = useContext(HubbleOrderContext)
  const windowWidth = useWindowWidth()
  const [isMobile, setIsMobile] = useState(false)
  const hubblePricing = getHubblePricing("default")

  useEffect(() => {
    setIsMobile(windowWidth < 1150)
  }, [windowWidth])

  const lenses = [
    {
      img: "Classic-Product-Image.png",
      title: <>Hubble<sup>®</sup> Classic Daily</>,
      description: !isMobile ?
        "Our original daily lens — it’s affordable, convenient, and comfortable for all-day wear."
        :
        "Our original daily contact lenses — they’re affordable, convenient, and comfortable for all-day wear. ",
      price: "$75.00/eye",
      firstPrice: "$1.00",
      firstPriceDescription: "15-day starter pack",
      type: "hubble",
      packSize: "90 pack (3-month supply)",
      buttonText: "Start Now for $1",
      cadence: 28,
    },
    {
      img: "Sign-Up-Hydro-Product-Image.png",
      title: <>Hydro by Hubble<sup>®</sup> Daily</>,
      description: "With innovative packaging, Hydro by Hubble is naturally hydrating and has a smooth lens edge.",
      price: "$115.00/eye",
      firstPrice: "$4.99/eye",
      firstPriceDescription: "30 pack (1-month supply)",
      type: "hydro",
      tagText: "Popular Choice",
      tagColor: "purple",
      packSize: "90 pack (3-month supply)",
      cadence: 28,
    },
    {
      img: "Sign-Up-SkyHy-Product-Image.png",
      title: <>SkyHy by Hubble<sup>®</sup> Daily</>,
      description: "As our most breathable lens, SkyHy by Hubble is made from silicone hydrogel, the material preferred by doctors.",
      price: "$145.00/eye",
      firstPrice: "$9.99/eye",
      firstPriceDescription: "30 pack (1-month supply)",
      type: "skyhy",
      tagText: "Premium Pick",
      tagColor: "orange",
      packSize: "90 pack (3-month supply)",
      cadence: 28,
    },
    {
      img: "Sign-Up-SkyHy-Monthly-Product-Image.png",
      title: <>SkyHy by Hubble<sup>®</sup> Monthly</>,
      description: !isMobile ?
        "All the comfort and breathability of SkyHy—now available as a monthly lens to fit the right wear schedule for you."
        :
        "All the comfort and breathability of SkyHy is now available as a monthly lens to fit the wear schedule that’s right for you.",
      price: "$49.99/eye • $8.33/eye per month",
      firstPrice: "$4.99/eye per month",
      firstPriceDescription: "$29.99/eye for your first 6 months",
      type: "skyhy-monthly",
      tagText: "NEW!",
      tagColor: "charged-blue",
      packSize: "6 pack (6-month supply)",
      cadence: 168,
      bannerLabel: "best value",
      bannerColor: "blue",
    }
  ]
  const lenseList = lenses

  const handleSelectBrand = (type) => {
    scrollTo("#order-section")
    setProduct(lensesData(hubblePricing).find(e => e.value.toLowerCase() === type.toLowerCase()))
  }

  const setProduct = (value) => {
      setContextBrand(value);
      if (prefill) setBrandSelected(true)
      setTaxes(0)
      setShippingAddress({
        first_name: "",
        last_name: "",
        address1: "",
        address2: "",
        city: "",
        province: "",
        zipcode: "",
        phone: "",
      })

      setTimeout(() => {
      const section = document.querySelector( '#order-section' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
      }, 100)


    // google datalayer events for select item
    window.dataLayer.push({ecommerce: null})
    window.dataLayer.push({
      event: "select_item",
      ecommerce: {
        items: [
          {
            item_id: value.cc_product_id || '2167100637257',
            index: 0,
            item_name: value.title,
            currency: "USD",
            discount: 0,
            item_brand: value.title.includes("Hubble") ? "Hubble" : value.title,
            item_category: "contacts",
            item_category2: 'spherical',
            item_category3: 'N/A', //gender
            item_category4: 'N/A',
            item_category5: 'N/A',
            item_list_id: "Normal",
            item_variant: 'N/A',
            price: parseFloat(value.sale_price)
          }
        ]
      }
    })


  }

  return (
    <section className="grid-section contacts-characteristics-canada v3" id="lenses-section">
      <h2 className="characteristics-title-v3 avg-signup">Hubble Contact Lenses</h2>
      <div className="content-container-contacts-v3">
        {lenseList.map(({ img, title, description, price, type, tagText, tagColor, bannerText, packSize, bannerLabel, bannerColor, buttonText, cadence, firstPrice, firstPriceDescription }) => {
          return (
            <div className={`item-block ${isPromo ? "promo" : ""}`} data-type={type} onClick={() => handleSelectBrand(type)}>
              {bannerText &&
                <div className="top-banner" data-type={type}>
                  {bannerText}
                </div>
              }
              <div className={`image-wrapper ${isPromo ? "promo" : ""}`}>
                <div>
                  <WebpImage
                    wrapperClassName="image"
                    className="image"
                    fileName={`Pages/Intake/${img}`}
                  />
                </div>
                {bannerLabel && <ProductTag text={bannerLabel} color={bannerColor} label className="banner-label" />}
                {(type !== "classic" && isPromo) && <ProductTag text={type === "hydro" && "80%\n OFF" || type === "skyhy" && "66%\n OFF"} label={isPromo} isPromo={isPromo} className="promo-tag" />}
                {tagText && <ProductTag text={tagText} color={tagColor} className="feature-tag" />}
              </div>

              <div className="text-block">
                <div>
                  <h2 className="lenses-title">{title}</h2>
                  <p className="description">{description}</p>
                </div>
                <div className='lenses-wrapper' data-type={type}>
                  <span className="pack-size">{packSize}</span>
                  <p
                    className="lenses-price"
                    dangerouslySetInnerHTML={{ __html: price }}
                  ></p>
                </div>
              </div>

              <div className="price-button-block">
                <div className="first-price-block">
                  <span className="first-price-header">special first-order offer</span>
                  <span className="first-price">{firstPrice}</span>
                  <span className="first-price-description">{firstPriceDescription}</span>
                </div>

                <div className="start-button" onClick={() => handleSelectBrand(type)}>
                  {buttonText || "Buy Now"}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
};

export default ContactsCharacteristics;
