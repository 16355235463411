import HeaderLogo from "../../../images/assets/Header/Logo.svg";
import WebpImage from "../../WebpImage";
import React, {useState} from "react";
import {Link} from "gatsby";
import "./styles.scss";

const Nav = ({usLink}) => {
  const [countryToggle, setCountryToggle] = useState(false);

  return (
    <div className="canada-signup-nav">
      <div className="content-wrapper grid-section">
        <div className="logo-wrapper">
          <HeaderLogo className="hubble-logo" />
        </div>

        <div className="controls">
          <a href="https://ca.hubblecontacts.com/account/login" className="login">
            <WebpImage fileName="assets/Header/User.svg" className="icon" alt="Login" />
            <span>Log In</span>
          </a>
          <div className="toggle-country-container">
            <div className="toggle-ca" onClick={() => setCountryToggle(!countryToggle)}>
              <WebpImage
                className="flag-image"
                fileName="assets/Header/CA-flag-round.png"
                alt="Canadian Flag"
              />
            </div>
            {countryToggle &&
              <Link to={usLink} className="toggle-us" onClick={() => setCountryToggle(!countryToggle)}>
                <WebpImage
                  className="flag-image"
                  fileName="assets/Header/US-flag-round.png"
                  alt="American Flag"
                />
              </Link>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Nav;
