export const getProducts = () => ([
  {
    value: "hubble",
    percent_off: "0",
    link: "",
    title: "Hubble Classic Daily",
    long_title: "Hubble Classic Daily Contact Lenses",
    image: "",
    price: "75.00",
    sale_price: "1.00",
    cc_product_id: "hubble",
    days: "84",
    quantity: 90,
    trialPeriod: 15,
  },
  {
    value: "hydro",
    percent_off: "",
    link: "",
    title: "Hydro by Hubble Daily",
    long_title: "Hydro by Hubble Daily Contact Lenses",
    short_title: "Hydro By Hubble",
    image: "",
    price: "115.00",
    sale_price: "4.99",
    cc_product_id: "hydro",
    days: "84",
    quantity: 90,
    trialQuantity: 30,
  },
  {
    value: "skyhy",
    percent_off: "",
    link: "",
    title: "SkyHy by Hubble Daily",
    long_title: "SkyHy by Hubble Daily Contact Lenses",
    short_title: "SkyHy by Hubble",
    image: "",
    price: "145.00",
    sale_price: "9.99",
    cc_product_id: "skyhy",
    days: "84",
    quantity: 90,
    trialQuantity: 30,
  },
  {
    value: "skyhy-monthly",
    percent_off: "",
    link: "",
    title: "SkyHy by Hubble Monthly",
    long_title: "SkyHy by Hubble Monthly Contact Lenses",
    short_title: "SkyHy by Hubble",
    image: "SkyHy-Monthly",
    price: "49.99",
    price_per_month: "8.33",
    sale_price: "29.99",
    sale_price_per_month: "4.99",
    cc_product_id: "skyhy-monthly",
    days: "168",
    quantity: 6,
  },
])
