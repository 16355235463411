import React, {useRef, useContext, useEffect} from "react";

import './styles.scss';
import BrandPrescription from "./BrandPrescription";
import HubbleOrderContext from "../../../context/hubbleOrderContext";

const OrderSection = () => {
  const { setDoctor } = useContext(HubbleOrderContext);

  const steps = [
    { title: 'Brand', id: 1, ref: useRef(null) },
    { title: 'Checkout', id: 4, ref: useRef(null) },
  ];

  useEffect(() => {
    setDoctor({id: "-1"})
  }, []);

  return (
    <section
      className="order-section-canada"
      id="order-section"
    >
      <div className="content-container grid-section">
        <h2 className="section-title">Order Your Contacts</h2>
        <div className="steps-container">
          <BrandPrescription
            steps={steps}
            isHydroSkyhy
            isV3
          />
        </div>
      </div>
    </section>
  )
};

export default OrderSection;
