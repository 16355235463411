import React, {useContext, useEffect, useState} from "react";
import Select, {components} from "react-select";

import HubbleOrderContext from "../../../../context/hubbleOrderContext";
import {getProducts} from "./productsList";
import StepWrapper from "../../../Intake/StepWrapper";
import "./styles.scss";
import Prescription from "./Prescription";

const BrandPrescription = ({ steps }) => {
  const { brand, setBrand, setTaxes, setShippingAddress, brandSelected, setBrandSelected, prescription, setPrescription } = useContext(HubbleOrderContext);
  const products = getProducts()
  const [productsData, setProductsData] = useState(products)
  const [product, setProduct] = useState(brand.title && brand.price ? brand : null);
  const [isError, setIsError] = useState(false);
  const [scrollNext, setScrollNext] =useState(false)

  useEffect(() => {
    setProductsData(products)
    setBrand(productsData[0])
  }, [])

  const getIsCurrent = (optionValue) => {
    const brandValue = optionValue
    const current = brand?.value
    const isCurrent = current?.toLowerCase() === brandValue

    return isCurrent
  }

  useEffect(() => {
   if (brand.value !== "") {
    setProduct(brand)
   }

    if (!brand) {
      return
    }

    const filteredProducts = products?.filter((product) => {
      const currentSelection = getIsCurrent(product?.value)
      return !currentSelection
    })

    const selectedProduct = products?.find((product) => {
      const currentSelection = getIsCurrent(product?.value)
      return currentSelection
    })

    const newProducts = [
      ...(selectedProduct ? [selectedProduct, ...filteredProducts] : filteredProducts)
    ]

    setProductsData(newProducts)
    setProduct(selectedProduct)
  }, [brand])

  const handleProduct = (value) => {
    setProduct(value);
    setBrand(value);
    setBrandSelected(true)
    setTaxes(0)
    setScrollNext(true)
    setPrescription({
      leftEye: {
        power: "",
      },
      rightEye: {
        power: "",
      },
    })
    setShippingAddress({
      first_name: "",
      last_name: "",
      address1: "",
      address2: "",
      city: "",
      province: "",
      zipcode: "",
      phone: "",
    })
      window.dataLayer.push({ecommerce: null})
      window.dataLayer.push({
        event: "select_item",
        ecommerce: {
          items: [
            {
              item_id: value.cc_product_id || '2167100637257',
              index: 0,
              item_name: value.title,
              currency: "USD",
              discount: 0,
              item_brand: value.title.includes("Hubble") ? "Hubble" : value.title,
              item_category: "contacts",
              item_category2: 'spherical',
              item_category3: 'N/A', //gender
              item_category4: 'N/A',
              item_category5: 'N/A',
              item_list_id: "Normal",
              item_variant: 'N/A',
              price: parseFloat(value.sale_price)
            }
          ]
        }
      })
  };

  const handleSubmit = () => {
    setIsError(false);

    if (!product) return setIsError(true);
  };

  const redirectToShopify = () => {
    window.location.href = `https://ca.hubblecontacts.com/pages/ampush-ca-bridge-page?doctor_id=100&left_power=${prescription.leftEye.power}&right_power=${prescription.rightEye.power}&plan_handle=${brand.value}`
  }

  useEffect(() => {
    product && brandSelected && handleSubmit()
  }, [product,scrollNext])

  return (
    <StepWrapper
      title="Enter Your Prescription"
      steps={steps}
      currentStep="Brand"
      onSubmit={handleSubmit}
      className="brand-prescription"
      isV3
    >
      <div className="brand-select-container">
        <label className="brand-select-label">Select Your Brand</label>
        <Select
          className={`hubble-select brand ${
            isError && !product ? 'error' : ''
          } v3-select`.trim()}
          classNamePrefix='HubbleSelect'
          value={brandSelected && product}
          onChange={handleProduct}
          placeholder="Select Brand"
          options={productsData}
          getIsCurrent={getIsCurrent}
          components={{ Option: OptionIntake, SingleValue: CustomSingleValue }}
          filterOption={(option, input) =>
            !input ||
            option.data.title?.toLowerCase?.().includes(input.toLowerCase()) ||
            option.label?.toLowerCase?.().includes(input.toLowerCase())
          }
        />
      </div>

      <Prescription isV3/>

      <button
        disabled={isError || !brand || !brandSelected || !prescription.leftEye.power || !prescription.rightEye.power}
        onClick={redirectToShopify}
        className="redirect-shopify-button"
      >Next Step</button>
    </StepWrapper>
  );
};

const OptionIntake = (props) => {
  const { title } = props.data

  return (
    <components.Option {...props}>
      <div className="text h8 neutral ocean-blue price-intake">
        {title}
      </div>
    </components.Option>
  )
}

const CustomSingleValue = (props) => {
  const { title } = props.data;

  return (
    <components.SingleValue {...props}>
      <div className="title">{title}</div>
    </components.SingleValue>
  )
};

export default BrandPrescription;
