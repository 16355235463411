import HeaderLogo from "../../../images/assets/Header/Logo.svg";
import React from "react";
import {Link} from "gatsby";
import "./styles.scss";

const links = [
  {
    label: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    label: "Terms of Service",
    link: "/terms-of-service",
  },
]

const Footer = () => {
  return (
    <div className="canada-signup-footer">
      <div className="content-container grid-section">
        <div className="first-section">
          <HeaderLogo className="hubble-logo" />

          <p className="support">Have a question? Give us a call at <span>1-844-334-1640</span>. Our customer service team is ready to help — 7 days a week from 9am-9pm Eastern Time.</p>
          <p className="faq">Or check out our <Link to="/faq-canada">most frequently asked questions</Link>.</p>
        </div>

        <div className="second-section">
          {links.map(l => <Link to={l.link} className="link">{l.label}</Link>)}
          <span className="rights-reserved">All Rights Reserved</span>
        </div>
      </div>
    </div>
  )
}

export default Footer;
