export const lensesData = (hubblePricing) => [
  {
    value: "hubble",
    percent_off: "0",
    link: "",
    title: "Hubble Classic Daily",
    long_title: "Hubble Classic Daily Contact Lenses",
    image: "",
    price: "22.99",
    sale_price: "1.00",
    cc_product_id: "hubble",
    days: "30",
    quantity: 30
  },
  {
    value: "hydro",
    percent_off: hubblePricing.hydro.percentOff,
    link: "",
    title: "Hydro by Hubble Daily",
    long_title: "Hydro by Hubble Daily Contact Lenses",
    short_title: "Hydro By Hubble",
    image: "",
    price: hubblePricing.hydro.subPrice,
    sale_price: hubblePricing.hydro.firstPrice,
    cc_product_id: "hydro",
    days: "30",
    quantity: 30
  },
  {
    value: "skyhy",
    percent_off: hubblePricing.skyhy.percentOff,
    link: "",
    title: "SkyHy by Hubble Daily",
    long_title: "SkyHy by Hubble Daily Contact Lenses",
    short_title: "SkyHy by Hubble",
    image: "",
    price: hubblePricing.skyhy.subPrice,
    sale_price: hubblePricing.skyhy.firstPrice,
    cc_product_id: "skyhy",
    days: "30",
    quantity: 30
  },
  {
    value: "skyhy-monthly",
    percent_off: hubblePricing.skyhyMonthly.percentOff,
    link: "",
    title: "SkyHy by Hubble Monthly",
    long_title: "SkyHy by Hubble Monthly Contact Lenses",
    short_title: "SkyHy by Hubble Monthly",
    image: "SkyHy-Monthly",
    price: hubblePricing.skyhyMonthly.subPrice,
    price_per_month: hubblePricing.skyhyMonthly.subPricePerMonth,
    sale_price: hubblePricing.skyhyMonthly.firstPrice,
    sale_price_per_month: hubblePricing.skyhyMonthly.firstPricePerMonth,
    cc_product_id: "skyhy-monthly",
    days: "180",
    quantity: 6
  }
]
